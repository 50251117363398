<template>
    <div>
        <div class="right-menu shipping-right">

            <subHeader pageName="USER MANAGEMENT"/>

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <moduleSearch :moduleName="'user_management'" :disable="tourIsActive" ref="module_search"/>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="usersTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == usersList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Image">Image</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="First Name">First Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Last Name">Last Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Email">Email</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Mobile No.">Mobile No.</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Roles">Roles</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Active/Disable</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="usersLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="usersList && usersList.length == 0">
                                        <td colspan="7" style="text-align:center"><p class="mb-0">No Users Available</p></td>
                                    </tr>
                                    <tr v-for="(listedUser, index) in usersList" :key="index">

                                        <!-- checkbox & image block starts here -->
                                        <td @click="previewUser(listedUser,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label 
                                                    class="ctmTableCheckContainer" 
                                                    data-open="false" 
                                                    @click.prevent=" listedUser.id != -1 ? checkedItemHandle(listedUser.id) : ''"
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        v-model="checkedItems" 
                                                        :value="listedUser.id" 
                                                        :disabled="tourIsActive || listedUser.id == -1" 
                                                        data-open="false"
                                                    >
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                                <!-- for saved user -->
                                                <div class="ctmDataTableImgBox" data-open="true" v-if="listedUser.id != -1">
                                                    <img 
                                                        :src="listedUser.image ? listedUser.image_src : '/images/no_image.png'" 
                                                        alt=""
                                                        v-if="listedUser.id == -1"
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    >
                                                    <img
                                                        :src="generateUrl(listedUser) ? generateUrl(listedUser) : '/images/no_image.png'"
                                                        alt=""
                                                        data-open="true"
                                                        @error="handleImageError"
                                                        v-else
                                                    >
                                                </div>
                                                <!-- for unsaved user -->
                                                <div class="ctmDataTableImgBox" data-open="true" v-else>
                                                    <img 
                                                        :src="listedUser.addImage ? listedUser.addImage_src : '/images/no_image.png'" 
                                                        alt=""
                                                        v-if="listedUser.id == -1"
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    >
                                                    <img
                                                        :src="generateUrl(listedUser) ? generateUrl(listedUser) : '/images/no_image.png'"
                                                        alt=""
                                                        data-open="true"
                                                        @error="handleImageError"
                                                        v-else
                                                    >
                                                </div>
                                            </div>
                                        </td>
                                        <!-- checkbox & image block ends here -->

                                        <!-- user first name block starts here -->
                                        <td @click="previewUser(listedUser,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="user-first-name-field-2">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a first name"
                                                    v-model="listedUser.first_name" 
                                                    @focus="userSelection(listedUser)" 
                                                    @blur="handleOTGUpdate(listedUser,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- user first name block ends here -->

                                        <!-- user last name block starts here -->
                                        <td @click="previewUser(listedUser,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="user-last-name-field-2">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a last name"
                                                    v-model="listedUser.last_name" 
                                                    @focus="userSelection(listedUser)" 
                                                    @blur="handleOTGUpdate(listedUser,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- user last name block ends here -->

                                        <!-- user email block starts here -->
                                        <td @click="previewUser(listedUser,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ 
                                                        listedUser.email ? listedUser.email.length > 25 ? 
                                                        listedUser.email.substr(0,25) + "..." : listedUser.email : "--" 
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- user email block ends here -->

                                        <!-- user mobile no. block starts here -->
                                        <td @click="previewUser(listedUser,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ listedUser.mobile_no ? listedUser.mobile_no : "--" }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- user mobile no. block ends here -->

                                        <!-- user roles block starts here -->
                                        <td @click="previewUser(listedUser,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt admins" data-open="true">
                                                <el-popover placement="left-start" title="User Roles" width="300" trigger="hover">
                                                    <el-table :data="listedUser.roles">
                                                        <el-table-column property="name" label="Name"></el-table-column>
                                                    </el-table>
                                                    <template #reference>
                                                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                            <title/>
                                                            <g data-name="31-User-Chart" id="_31-User-Chart">
                                                                <path d="M2,29c0-.78,1.92-1.7,3.24-2l.11,0,8-3A1,1,0,0,0,14,23V20.31a1,1,0,0,0-.57-.9A6,6,0,0,1,10,14a1,1,0,0,0-1-1V11a1,1,0,0,0,1-1V8A6,6,0,0,1,22,8v2a1,1,0,0,0,1,1v2h2V11a2,2,0,0,0-1-1.73V8A8,8,0,0,0,8,8V9.27A2,2,0,0,0,7,11v2a2,2,0,0,0,1,1.75,8.07,8.07,0,0,0,4,6.16v1.39L4.7,25c-.91.23-4.7,1.37-4.7,4v2a1,1,0,0,0,1,1H15V30H2Z"/>
                                                                <rect height="2" width="2" x="16" y="21"/>
                                                                <path d="M22,16v5H20v2h2v5a1,1,0,0,0,1,1h4V27H24V23h3V21H24V17h3V15H23A1,1,0,0,0,22,16Z"/>
                                                                <rect height="2" width="2" x="29" y="15"/>
                                                                <rect height="2" width="2" x="29" y="21"/>
                                                                <rect height="2" width="2" x="29" y="27"/>
                                                            </g>
                                                        </svg>
                                                    </template>
                                                </el-popover>
                                            </div>
                                        </td>
                                        <!-- user roles block ends here -->

                                        <!-- active/disabled block starts here -->
                                        <td @click="previewUser(listedUser,index,'input',$event)">
                                            <div class="actionSwitch" data-open="true">
                                                <label class="switch" data-open="false" v-if="listedUser.id != -1">
                                                    <input
                                                        type="checkbox"
                                                        :id="`customSwitch-p-${listedUser.id}`"
                                                        :value="listedUser.isActive"
                                                        v-model="listedUser.isActive"
                                                        :disabled="tourIsActive || (listedUser.id == $store.state.user.id) || (listedUser.role == 'super-admin')"
                                                        data-open="false"
                                                    >
                                                    <span class="slider" data-open="false" @click.stop="updateUserStatusHandle(listedUser,index)"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- active/disabled block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update account form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''" >
                            <div class="tableActionBtns" id="user-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="viewOrderTitle">
                                                <p>
                                                    {{ 
                                                        (previewForm.first_name + " " + previewForm.last_name).length > 20 ?
                                                        (previewForm.first_name + " " + previewForm.last_name).substr(0,20) + "..." : 
                                                        (previewForm.first_name + " " + previewForm.last_name) 
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active" id="category-detail-navbtn" href="#userDetails">User Info</a>
                                        </li>
                                    </ul>
                                    <div class="statusColumnBox">
                                        <div class="actionSwitch" id="user-status-switch">
                                            <label class="switch">
                                                <input 
                                                    type="checkbox"
                                                    v-model="previewForm.isActive"
                                                    :disabled="tourIsActive"
                                                >
                                                <span class="slider"></span>
                                                <p class="ctmSwitchLabel">{{ previewForm.isActive ? 'Active' : 'in-Active' }}</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="userFormObserver">
                                <div class="productTableInfoBlock">
                                    <div class="productTableInfoWhiteBox" id="userDetails">

                                        <!-- image for add user -->
                                        <div class="uploadNewUserImg" id="user-image" v-if="this.previewForm.id == -1">
                                            <div class="userDetailsImg">
                                                <img 
                                                    :src="previewForm.addImage ? previewForm.addImage_src ? previewForm.addImage_src : base_image_url + setting.store_id + '/user/' + previewForm.addImage : '/images/customer_noImage.png' " 
                                                    alt=""
                                                    @error="handleImageError"
                                                />
                                            </div>
                                            <div class="uploadImgFile" id="add-user-image">
                                                <input type="file" ref="addPreviewFileInput" @change.prevent="changeAddImage" accept="image/png,image/jpg,image/jpeg,image/webp" :disabled="tourIsActive">
                                                <button>
                                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <span 
                                            class="text-danger mx-auto" 
                                            style="text-align:center; display:block;" 
                                            v-if="this.previewForm.id == -1 && imageValidations.imgIsRequired"
                                        >
                                            Image field is required
                                        </span>
                                        
                                        <!-- image for update user -->
                                        <div class="uploadNewUserImg" id="user-image" v-if="this.previewForm.id != -1">
                                            <div class="userDetailsImg">
                                                <img 
                                                    :src="previewForm.image ? previewForm.image_src ? previewForm.image_src : base_image_url + settings.store_id + '/user/' + previewForm.image : '/images/customer_noImage.png' " 
                                                    alt=""
                                                    @error="handleImageError"
                                                />
                                            </div>
                                            <div class="uploadImgFile">
                                                <input type="file" ref="previewFileInput" @change.prevent="handleChangeEditImage" accept="image/png,image/jpg,image/jpeg,image/webp" :disabled="tourIsActive">
                                                <button>
                                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <span 
                                            class="text-danger mx-auto" 
                                            style="text-align:center; display:block;" 
                                            v-if="this.previewForm.id != -1 && imageValidations.imgIsRequired"
                                        >
                                            Image field is required
                                        </span>

                                        <form id="user-information">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <ValidationProvider name="first name" :rules="{required:true,max:50}" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="user-first-name-field-1">First Name<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="text" 
                                                                    name="user-first-name-field-1" 
                                                                    id="user-first-name-field-1" 
                                                                    placeholder="First name" 
                                                                    v-model="previewForm.first_name"
                                                                    :disabled="tourIsActive"
                                                                >
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-6">
                                                    <ValidationProvider name="last name" :rules="{required:true,max:50}" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="user-last-name-field-1">Last Name<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="text" 
                                                                    name="user-last-name-field-1" 
                                                                    id="user-last-name-field-1" 
                                                                    placeholder="Last name" 
                                                                    v-model="previewForm.last_name"
                                                                    :disabled="tourIsActive"
                                                                >
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-6">
                                                    <ValidationProvider name="email" :rules="{required:true,max:250}" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="user-email-field-1">Email<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="text" 
                                                                    name="user-email-field-1" 
                                                                    id="user-email-field-1" 
                                                                    placeholder="Email" 
                                                                    v-model="previewForm.email"
                                                                    :disabled="tourIsActive"
                                                                >
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-6">
                                                    <ValidationProvider name="city" :rules="{required:true,max:250}" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="user-city-field-1">City<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="text" 
                                                                    name="user-city-field-1" 
                                                                    id="user-city-field-1" 
                                                                    placeholder="City" 
                                                                    v-model="previewForm.city" 
                                                                    :disabled="tourIsActive"
                                                                >
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div :class="previewForm.id == -1 ? 'col-md-6' : 'col-md-12'" id="mobile-no-field">
                                                    <div class="tableFromFieldBox">
                                                        <label for="user-mobile-no-field">Mobile No.<span>*</span></label>
                                                        <div class="tableFromFieldItem">
                                                            <contactInput
                                                                :contactNo="previewForm.mobile_no"
                                                                :placeHolder="'Mobile no.'"
                                                                :setValidations="setMobileNoFieldValidations"
                                                                :disabled="tourIsActive"
                                                                ref="contactInputfield"
                                                            />
                                                            <span class="text-danger" v-if="!countryIsSelected && contactFieldTouched">
                                                                Select a country from the dropdown menu or add the country code before the mobile no.
                                                            </span>
                                                            <span class="text-danger" v-else-if="!contactIsValid && contactFieldTouched">
                                                                Enter a valid mobile no.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" v-if="previewForm.id == -1">
                                                    <ValidationProvider name="password" :rules="{required:true,min:8,max:250,regex:/^\S.*\S$/}" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="password-field">Password<span>*</span></label>
                                                            <div class="tableFromFieldItem passwordBtnIconPd">
                                                                <a href="" class="genPasswordBtn" @click.prevent="generatePassword('preview',16)">Generate Password</a>
                                                                <input 
                                                                    :type="fieldTypePassword ? 'password' : 'text'" 
                                                                    ref="passwordInput" 
                                                                    name="password-field" 
                                                                    id="password-field" 
                                                                    placeholder="Password" 
                                                                    autocomplete="true"
                                                                    v-model="previewForm.password"
                                                                    :disabled="tourIsActive"
                                                                >
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                                <button class="passwordEyeBtn" type="button" @click.prevent="fieldTypePassword = !fieldTypePassword">
                                                                    <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="!fieldTypePassword">
                                                                        <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                                                                    </svg>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                                                                        <g id="eyeOpen" transform="translate(-355 -428)">
                                                                            <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                                                                                <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"/>
                                                                            </g>
                                                                            <rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none"/>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-12">
                                                    <ValidationProvider name="address line 1" :rules="{required:true,max:250}" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="user-address-line-1-field">Address Line 1<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="text" 
                                                                    name="user-address-line-1-field" 
                                                                    id="user-address-line-1-field" 
                                                                    placeholder="Address line 1" 
                                                                    v-model="previewForm.address_line_1"
                                                                    :disabled="tourIsActive"
                                                                >
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-12">
                                                    <ValidationProvider name="address line 2" :rules="{max:250}" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="user-address-line-2-field">Address Line 2</label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="text" 
                                                                    name="user-address-line-2-field" 
                                                                    id="user-address-line-2-field" 
                                                                    placeholder="Address line 2" 
                                                                    v-model="previewForm.address_line_2"
                                                                    :disabled="tourIsActive" 
                                                                >
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-12" id="roles-field">
                                                    <ValidationProvider name="roles" rules="required" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox" style="position: relative;">
                                                            <label for="userRoles">Roles<span>*</span></label>
                                                            <div class="vueSelectBlock">
                                                                <Multiselect
                                                                    v-model="previewForm.roles"
                                                                    id="userRoles"
                                                                    :clearOnSelect="true"
                                                                    :options="rolesList"
                                                                    :hideSelected="false"
                                                                    :showNoResults="true"
                                                                    :multiple="true"
                                                                    :object="true"
                                                                    :showOptions="true"
                                                                    :searchable="true"
                                                                    track-by="name"
                                                                    label="name"
                                                                    openDirection="top"
                                                                    placeholder="Select role"
                                                                    :disabled="tourIsActive"
                                                                /> 
                                                            </div>
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="customerDetailformBtns">
                                                <button 
                                                    type="button" 
                                                    @click.prevent="passwordPopupHandle('open')" 
                                                    v-if="previewForm.id != -1" 
                                                    :disabled="userIsSaving"
                                                >
                                                    Change Password
                                                </button>
                                                <loaderBtn v-if="userIsSaving"/>
                                                <button 
                                                    type="submit" 
                                                    @click.prevent="validate('preview')" 
                                                    :disabled="tourIsActive" 
                                                    id="save-user-btn" 
                                                    v-else
                                                >
                                                    {{ previewForm.id == -1 ? 'Save' : 'Update' }}
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- add/update account form ends here -->

                    </div>
                    <!-- data table ends here -->

                    <!-- bottom action bar starts here -->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != usersList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-4" @click.prevent="userBulkActionHandle('active')">
                                    <p>ACTIVE</p>
                                </a>
                                <a href="" class="model-footer-1 mr-4" @click.prevent="userBulkActionHandle('disable')">
                                    <p>DISABLE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- bottom action bar ends here -->
                    
                </div>
            </div>

            <!-- password reset modal starts here -->
            <div class="modalBg passwordResetPopup" :class="passwordForm.isOpen ? 'modal-active': ''">
                <div class="modalLayers" @click.prevent="passwordPopupHandle('close')"></div>
                <div class="modalBody">
                    <div class="oderSlideBox productSideBox" style="position:relative;top:15px;right:0;width:100%">
                        <ValidationObserver ref="userPasswordObserver">
                            <form @submit.prevent="validate('password')">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ValidationProvider 
                                            name="password" 
                                            :rules="{required:true,min:8,max:250,regex:/^\S.*\S$/}" 
                                            vid="password" 
                                            v-slot="{ errors }" 
                                        >
                                            <div class="formContentBox">
                                                <label class="formLabelCustomer" for="newPassword">New Password <span>*</span></label>
                                                <input 
                                                    :type="passwordForm.fieldTypePassword ? 'password' :'text' " 
                                                    class="form-control" 
                                                    id="newPassword"
                                                    v-model="passwordForm.password" 
                                                    placeholder="New password"
                                                    autocomplete
                                                >
                                                <span class="text-danger">{{ errors[0] }}</span>
                                                <button 
                                                    type="button" 
                                                    class="changePasswordEyeBtn" 
                                                    @click.prevent="passwordForm.fieldTypePassword = !passwordForm.fieldTypePassword"
                                                >
                                                    <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="!passwordForm.fieldTypePassword">
                                                        <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                                                    </svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                                                        <g id="eyeOpen" transform="translate(-355 -428)">
                                                            <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                                                                <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"/>
                                                            </g>
                                                            <rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none"/>
                                                        </g>
                                                    </svg>
                                                </button>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-md-12">
                                        <ValidationProvider 
                                            name="confirm password" 
                                            :rules="{required:true,min:8,max:250,regex:/^\S.*\S$/,confirmed:'password'}" 
                                            v-slot="{ errors }"
                                        >
                                            <div class="formContentBox">
                                                <label class="formLabelCustomer" for="confirmPassword">Confirm Password <span>*</span></label>
                                                <input 
                                                    :type="passwordForm.confirmFieldTypePassword ? 'password' : 'text' "
                                                    class="form-control" 
                                                    id="confirmPassword"
                                                    v-model="passwordForm.confirm_password" 
                                                    placeholder="Confirm password"
                                                    autocomplete
                                                >
                                                <span class="text-danger">{{ errors[0] }}</span>
                                                <button 
                                                    type="button" 
                                                    class="changePasswordEyeBtn" 
                                                    @click.prevent="passwordForm.confirmFieldTypePassword = !passwordForm.confirmFieldTypePassword"
                                                >
                                                    <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="!passwordForm.confirmFieldTypePassword">
                                                        <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                                                    </svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                                                        <g id="eyeOpen" transform="translate(-355 -428)">
                                                            <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                                                                <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"/>
                                                            </g>
                                                            <rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none"/>
                                                        </g>
                                                    </svg>
                                                </button>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="formCustomerBtn">
                                            <loaderBtn v-if="passwordForm.isSaving"/>
                                            <button v-else type="submit">Update password</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                    <div class="modalCloseIcon">
                        <button @click.prevent="passwordPopupHandle('close')">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <!-- password reset modal ends here -->
            
        </div>

        <!-- vue tour -->
        <v-tour name="userManagementTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import {MessageBox, Message, Loading} from 'element-ui'
import _ from 'lodash';
import Multiselect from "vue-multiselect";
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import { mapGetters } from 'vuex';
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";
import contactInput from "@/components/contactInput.vue";
import moduleSearch from "@/components/ModuleSearch/index.vue";

export default {
    metaInfo(){
        return {
            title: 'User Management | YeetCommerce',
        };
    },
    data(){
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,

            //users start
            usersLoading:false,
            userIsSaving:false,
            userSaved:false,
            previewForm:{
                id:'',
                first_name:'',
                last_name:'',
                email:'',
                address_line_1:'',
                address_line_2:'',
                mobile_no:'',
                role:'',
                roles:[],
                city:'',
                isActive:true,
                isTouched:false,
                image: null,
                image_src: '',
                addImage:null,
                addImage_src:'',
            },
            defaultPreviewForm:{
                id:'',
                first_name:'',
                last_name:'',
                email:'',
                address_line_1:'',
                address_line_2:'',
                mobile_no:'',
                role:'',
                roles:[],
                city:'',
                isActive:true,
                isTouched:false,
                image: null,
                image_src: '',
                addImage:null,
                addImage_src:'',
            },
            tempPreviewUser:{
                id:'',
                first_name:'',
                last_name:'',
                email:'',
                address_line_1:'',
                address_line_2:'',
                mobile_no:'',
                role:'',
                roles:[],
                city:'',
                isActive:true,
                isTouched:false,
                image: null,
                image_src: '',
                addImage:null,
                addImage_src:'',
            },
            tempUser:{
                id:'',
                first_name:'',
                last_name:'',
                email:'',
                address_line_1:'',
                address_line_2:'',
                mobile_no:'',
                role:'',
                roles:[],
                city:'',
                isActive:true,
                isTouched:false,
                image: null,
                image_src: '',
                addImage:null,
                addImage_src:'',
            },
            usersList:[],
            rolesList:[],
            fieldTypePassword:true,
            //users end

            //image validations start
            imageValidations:{
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            },
            //image validations end

            //mobile no. properties start
            contactIsValid:true,
            countryIsSelected:false,
            contactFieldTouched:false,
            selectedContactObj:{},
            selectedContactCountry:[],
            //mobile no. properties end

            //password form start
            passwordForm: {
                password: '',
                confirm_password: '',
                fieldTypePassword:true,
                confirmFieldTypePassword:true,
                id: '',
                isOpen: false,
                isSaving: false
            },
            //password form end

            //checked items start
            checkedItems:[],
            deletingItems:[],
            deleteLoader:false,
            isMasterSearched:false,
            //checked items end

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //user management steps
                {
                    target: '#module-search',
                    content: `<strong>Search User</strong><br>It enables you to search specific users.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#preview-record',
                    content: `<strong>Users Table</strong><br>You can preview and edit the added users by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>User</strong><br>By clicking this button, you can add a new user and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#user-details-preview',
                    content: `<strong>User Details Form</strong><br>This form can be used to add details about the user and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#add-user-image',
                    content: `<strong>User Image</strong><br>Image of the user can be added using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#user-first-name-field-1',
                    content: `<strong>First Name Field</strong><br>First name of the user can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#user-last-name-field-1',
                    content: `<strong>Last Name Field</strong><br>Last name of the user can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#user-email-field-1',
                    content: `<strong>Email Field</strong><br>Email of the user can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#user-city-field-1',
                    content: `<strong>City Field</strong><br>City of the user can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#mobile-no-field',
                    content: `<strong>Mobile No. Field</strong><br>Mobile no. of the user can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#password-field',
                    content: `<strong>Password Field</strong><br>Password of the user can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#user-address-line-1-field',
                    content: `<strong>Address Line 1 Field</strong><br>address of the user can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#user-address-line-2-field',
                    content: `<strong>Address Line 2 Field</strong><br>address of the user can also be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'top'
                    }
                },
                {
                    target: '#roles-field',
                    content: `<strong>User Roles Field</strong><br>You can assign the roles to a user using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#user-first-name-field-2',
                    content: `<strong>First Name Field</strong><br>First name of the user can also be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#user-last-name-field-2',
                    content: `<strong>Last Name Field</strong><br>Last name of the user can also be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#user-status-switch',
                    content: `<strong>User Status Switch</strong><br>Status of the user can be switched from here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#save-user-btn',
                    content: `<strong>Save User Details</strong><br>Added user details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
            ],
            tourIsActive:false,
            //tour end
        }
    },
    components:{
        subHeader,
        Multiselect,
        tutorialsAction,
        loaderBtn,
        moduleStats,
        contactInput,
        moduleSearch,
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            storedAdmins:'admin_users_module/admins',
            adminsCurrentPage: 'admin_users_module/currentPage',
            adminsLastPage:'admin_users_module/lastPage',
            adminSearchKeyword:'admin_users_module/keyword',
            isPending: 'admin_users_module/isPending',
            storeAdminRoles:'admin_users_module/roles',
            storeAllCountries: 'countries_module/allCountries'
        }),
    },
    watch:{
        'storedAdmins':{
            handler:function(value){

                this.usersLoading = false;

                if(this.previewForm.id == -1){
                    
                    this.usersList = _.cloneDeep(value);

                    this.usersList.unshift(this.previewForm);

                }else if(this.tempPreviewUser.id != ''){
                    
                    this.usersList = _.cloneDeep(value);

                    this.usersList[this.previewForm.index] = this.previewForm;

                }else{

                    this.usersList = _.cloneDeep(value);

                }

                if(this.isMasterSearched){

                    if(this.usersList.length > 0){

                        this.previewSearchedUser(this.usersList[0],0);

                        this.isMasterSearched = false;

                    }else{

                        this.isMasterSearched = false;

                    }

                }

            },deep:true
        },
        'storeAdminRoles':{
            handler:function(value){

                this.rolesList = value;

            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if((value.id == -1) && (value.first_name || value.last_name || value.email ||
                value.mobile_no || value.city || value.address_line_1 || value.address_line_2 ||
                value.password || value.roles.length > 0)){
                        
                    value.isTouched = true;

                }else{

                    value.isTouched = false;

                }

            },deep:true
        },
    },
    methods:{
        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.usersTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.adminsCurrentPage != this.adminsLastPage){

                    this.$store.dispatch('admin_users_module/fetchStoreAdminUsersRequest',{size: 10, page: this.adminsCurrentPage + 1, loadData: 'more'});
                
                }

            }

        },
        //infinite scroll end

        //OTG update start
        userSelection(listedUser){

            this.tempUser = _.cloneDeep(listedUser);

        },
        async handleOTGUpdate(listedUser,index){
        
            if(this.previewForm.id == ''){
            
                if(listedUser.id != -1){

                    if((this.tempUser.first_name != listedUser.first_name) ||
                    (this.tempUser.last_name != listedUser.last_name)){

                        if(listedUser.first_name.length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The user's first name field is required.",
                            });

                            this.usersList[index].first_name = this.tempUser.first_name;

                            return

                        }
                        if(listedUser.first_name.length > 50){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The user's first name may not be greater than 50 characters.",
                            });

                            this.usersList[index].first_name = this.tempUser.first_name;

                            return

                        }

                        if(listedUser.last_name.length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The user's last name field is required.",
                            });

                            this.usersList[index].last_name = this.tempUser.last_name;

                            return

                        }
                        if(listedUser.last_name.length > 50){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The user's last name may not be greater than 50 characters.",
                            });

                            this.usersList[index].last_name = this.tempUser.last_name;

                            return

                        }

                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                distinguishCancelAndClose: true,
                                confirmButtonText: 'Save',
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(async () => {

                            let formData = new FormData();

                            formData.append('id',listedUser.id);
                            formData.append('first_name',listedUser.first_name);
                            formData.append('last_name',listedUser.last_name);
                            formData.append('email',listedUser.email);
                            formData.append('city',listedUser.city);
                            formData.append('mobile_no',listedUser.mobile_no.replace(/\s/g,''));
                            formData.append('address_line_1',listedUser.address_line_1);

                            if(listedUser.address_line_2){

                                formData.append('address_line_2',listedUser.address_line_2);

                            }

                            formData.append('isActive',listedUser.isActive == 1 ? 1 : 0);

                            formData.append('roles',JSON.stringify(listedUser.roles));

                            let loader = Loading.service({
                                text: "The user is being updated. Please wait!",
                                fullscreen: true,
                            });

                            try{
                                let res = await this.$axios.post('/admin/update',formData);
                                if(res.data.status_code == "1190"){

                                    this.$notify({
                                        type: 'success',
                                        title: 'Success',
                                        message: "User has been successfully updated.",
                                    });

                                    let admin = res.data.admin;
                                    admin.isActive = admin.isActive ? true : false;

                                    let payload = {
                                        index: index,
                                        updatedUser: admin
                                    }

                                    this.$store.commit('admin_users_module/update_admin_users_list',payload);

                                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                    this.$store.commit('gallery_module/CLEAR_MODULE');

                                }
                            }catch(error){

                                this.usersList[index].first_name = this.tempUser.first_name;
                                this.usersList[index].last_name = this.tempUser.last_name;

                                if(error.response){

                                    if(error.response.data.error.includes('storage limit has been reached')){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error,
                                        });

                                    }else if(error.response.data.error.includes('existing account')){
                                    
                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error,
                                        });

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                }else{

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.message,
                                    });

                                }

                            }finally{

                                loader.close();

                            }

                        }).catch(() => {

                            this.usersList[index].first_name = this.tempUser.first_name;
                            this.usersList[index].last_name = this.tempUser.last_name;

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes discarded successfully.",
                            });

                            this.tempUser = [];

                        });

                    }

                }
            
            }else{

                if(listedUser.id != -1){
                
                    if((this.tempUser.first_name != listedUser.first_name) ||
                    (this.tempUser.last_name != listedUser.last_name)){

                        if(listedUser.first_name.length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The user's first name field is required.",
                            });

                            this.usersList[index].first_name = this.tempUser.first_name;

                            return

                        }
                        if(listedUser.first_name.length > 50){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The user's first name may not be greater than 50 characters.",
                            });

                            this.usersList[index].first_name = this.tempUser.first_name;

                            return

                        }

                        if(listedUser.last_name.length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The user's last name field is required.",
                            });

                            this.usersList[index].last_name = this.tempUser.last_name;

                            return

                        }
                        if(listedUser.last_name.length > 50){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The user's last name may not be greater than 50 characters.",
                            });

                            this.usersList[index].last_name = this.tempUser.last_name;

                            return

                        }

                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                distinguishCancelAndClose: true,
                                confirmButtonText: 'Save',
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(async () => {

                            let formData = new FormData();

                            formData.append('id',listedUser.id);
                            formData.append('first_name',listedUser.first_name);
                            formData.append('last_name',listedUser.last_name);
                            formData.append('email',listedUser.email);
                            formData.append('city',listedUser.city);
                            formData.append('mobile_no',listedUser.mobile_no.replace(/\s/g,''));
                            formData.append('address_line_1',listedUser.address_line_1);

                            if(listedUser.address_line_2){

                                formData.append('address_line_2',listedUser.address_line_2);

                            }

                            formData.append('isActive',listedUser.isActive == 1 ? 1 : 0);

                            formData.append('roles',JSON.stringify(listedUser.roles));

                            let loader = Loading.service({
                                text: "The user is being updated. Please wait!",
                                fullscreen: true,
                            });

                            try{
                                let res = await this.$axios.post('/admin/update',formData);
                                if(res.data.status_code == "1190"){

                                    this.$notify({
                                        type: 'success',
                                        title: 'Success',
                                        message: "User has been successfully updated.",
                                    });

                                    let admin = res.data.admin
                                    admin.isActive = admin.isActive ? true : false;

                                    let payload = {
                                        index: index,
                                        updatedUser: admin
                                    }

                                    this.$store.commit('admin_users_module/update_admin_users_list',payload);

                                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                    this.$store.commit('gallery_module/CLEAR_MODULE');

                                }
                            }catch(error){

                                this.usersList[index].first_name = this.tempUser.first_name;
                                this.usersList[index].last_name = this.tempUser.last_name;

                                if(error.response){

                                    if(error.response.data.error.includes('storage limit has been reached')){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error,
                                        });

                                    }else if(error.response.data.error.includes('existing account')){
                                    
                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error,
                                        });

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                }else{

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.message,
                                    });

                                }

                            }finally{

                                loader.close();

                            }

                        }).catch(() => {

                            this.usersList[index].first_name = this.tempUser.first_name;
                            this.usersList[index].last_name = this.tempUser.last_name;

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes discarded successfully.",
                            });

                            this.tempUser = [];

                        });

                    }

                }

            }

        },
        //OTG update end

        //preview form open/close & reset start
        previewUser(listedUser,i,field,e){

            if(field == 'input'){

                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){
                
                    if(listedUser.id != -1){
                    
                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.usersList.findIndex((user) => user.id == -1);

                                if(index != -1){

                                    this.usersList.splice(index,1); 

                                }

                                if(!this.userSaved){

                                    let tempIndex = this.usersList.findIndex(x=>x.id == this.tempPreviewUser.id);

                                    if(tempIndex != -1){

                                        this.usersList[tempIndex] = this.tempPreviewUser;

                                    }

                                }

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = ''

                                }

                                let user = listedUser;

                                this.previewForm = user;
                                this.previewForm.index = i;

                                this.previewForm.image_src = null
                                this.previewForm.image = user.image

                                let tempUserRoles = [];

                                user.roles.forEach(role=>{

                                    let obj = {
                                        name : role.name
                                    }

                                    tempUserRoles.push(obj);

                                });

                                this.previewForm.roles = tempUserRoles;

                                this.checkedItems = [];

                                this.userIsSaving = false;

                                this.tempPreviewUser = _.cloneDeep(user);

                            }).catch(() => {})

                        }else{

                            let index = this.usersList.findIndex((user) => user.id == -1);
                                
                            if(index != -1){
                
                                this.usersList.splice(index, 1);
                
                            }

                            if(!this.userSaved && (this.tempPreviewUser.id != '' && this.tempPreviewUser.id != listedUser.id)){
                                
                                let tempIndex = this.usersList.findIndex(x=>x.id == this.tempPreviewUser.id);
                                
                                if(tempIndex != -1){

                                    this.usersList[tempIndex] = this.tempPreviewUser;

                                }
                
                            }

                            if(this.tempPreviewUser.id != '' && this.tempPreviewUser.id == listedUser.id){
                            
                                if(listedUser.role == "super-admin"){

                                    this.$notify({
                                        title: 'Alert',
                                        type: 'warning',
                                        message: "Super admin cannot be changed.",
                                    });

                                    return 

                                }

                                if(listedUser.id == this.$store.state.user.id && listedUser.role != "super-admin"){

                                    this.$notify({
                                        title: 'Alert',
                                        type: 'warning',
                                        message: "Only super admin can edit this profile",
                                    });

                                    return 

                                }

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = ''

                                }

                                let user = listedUser;

                                this.previewForm = user;
                                this.previewForm.index = i;

                                this.previewForm.image_src = null
                                this.previewForm.image = user.image

                                let tempUserRoles = [];

                                user.roles.forEach(role=>{

                                    let obj = {
                                        name : role.name
                                    }

                                    tempUserRoles.push(obj)

                                });

                                this.previewForm.roles = tempUserRoles;

                                this.checkedItems = [];

                                this.userIsSaving = false;

                                this.tempPreviewUser = _.cloneDeep(user);

                            }else{

                                if(listedUser.role == "super-admin"){

                                    this.$notify({
                                        title: 'Alert',
                                        type: 'warning',
                                        message: "Super admin cannot be changed.",
                                    });

                                    return 

                                }

                                if(listedUser.id == this.$store.state.user.id && listedUser.role != "super-admin"){

                                    this.$notify({
                                        title: 'Alert',
                                        type: 'warning',
                                        message: "Only super admin can edit this profile",
                                    });

                                    return 

                                }

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = ''

                                }

                                let user = listedUser;

                                this.previewForm = user;
                                this.previewForm.index = i;

                                this.previewForm.image_src = null
                                this.previewForm.image = user.image

                                let tempUserRoles = [];

                                user.roles.forEach(role=>{

                                    let obj = {
                                        name : role.name
                                    }

                                    tempUserRoles.push(obj)

                                });

                                this.previewForm.roles = tempUserRoles;

                                this.checkedItems = [];

                                this.userIsSaving = false;

                                this.tempPreviewUser = _.cloneDeep(user);

                            }

                        }

                    }
                
                }

            }else{

                if(!this.tourIsActive){
                
                    if(listedUser.id != -1){
                    
                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                
                                let index = this.usersList.findIndex((user) => user.id == -1);

                                if(index != -1){

                                    this.usersList.splice(index,1); 

                                }

                                if(!this.userSaved){

                                    let tempIndex = this.usersList.findIndex(x=>x.id == this.tempPreviewUser.id);

                                    if(tempIndex != -1){

                                        this.usersList[tempIndex] = this.tempPreviewUser

                                    }

                                }

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = ''

                                }

                                let user = listedUser;

                                this.previewForm = user;
                                this.previewForm.index = i;

                                this.previewForm.image_src = null;
                                this.previewForm.image = user.image;

                                let tempUserRoles = [];

                                user.roles.forEach(role=>{

                                    let obj = {
                                        name : role.name
                                    }

                                    tempUserRoles.push(obj);

                                });

                                this.previewForm.roles = tempUserRoles;

                                this.checkedItems = [];

                                this.userIsSaving = false;

                                this.tempPreviewUser = _.cloneDeep(user);

                            }).catch(() => {})

                        }else{

                            let index = this.usersList.findIndex((user) => user.id == -1);
                                
                            if(index != -1){

                                this.usersList.splice(index, 1);

                            }

                            if(!this.userSaved && (this.tempPreviewUser.id != '' && this.tempPreviewUser.id != listedUser.id)){
                                
                                let tempIndex = this.usersList.findIndex(x=>x.id == this.tempPreviewUser.id);

                                if(tempIndex != -1){

                                    this.usersList[tempIndex] = this.tempPreviewUser;

                                }

                            }

                            if(this.tempPreviewUser.id != '' && this.tempPreviewUser.id == listedUser.id){

                                if(listedUser.role == "super-admin"){

                                    this.$notify({
                                        title: 'Alert',
                                        type: 'warning',
                                        message: "Super admin cannot be changed.",
                                    });

                                    return 

                                }

                                if(listedUser.id == this.$store.state.user.id && listedUser.role != "super-admin"){

                                    this.$notify({
                                        title: 'Alert',
                                        type: 'warning',
                                        message: "Only super admin can edit this profile",
                                    });

                                    return 

                                }

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = ''

                                }

                                let user = listedUser;

                                this.previewForm = user;
                                this.previewForm.index = i;

                                this.previewForm.image_src = null;
                                this.previewForm.image = user.image;

                                let tempUserRoles = [];

                                user.roles.forEach(role=>{

                                    let obj = {
                                        name : role.name
                                    }

                                    tempUserRoles.push(obj);

                                });

                                this.previewForm.roles = tempUserRoles;

                                this.checkedItems = [];

                                this.userIsSaving = false;

                                this.tempPreviewUser = _.cloneDeep(user);

                            }else{

                                if(listedUser.role == "super-admin"){

                                    this.$notify({
                                        title: 'Alert',
                                        type: 'warning',
                                        message: "Super admin cannot be changed.",
                                    });

                                    return 

                                }

                                if(listedUser.id == this.$store.state.user.id && listedUser.role != "super-admin"){

                                    this.$notify({
                                        title: 'Alert',
                                        type: 'warning',
                                        message: "Only super admin can edit this profile",
                                    });

                                    return 

                                }

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = ''

                                }

                                let user = listedUser;

                                this.previewForm = user;
                                this.previewForm.index = i;

                                this.previewForm.image_src = null
                                this.previewForm.image = user.image

                                let tempUserRoles = [];

                                user.roles.forEach(role=>{

                                    let obj = {
                                        name : role.name
                                    }

                                    tempUserRoles.push(obj);

                                });

                                this.previewForm.roles = tempUserRoles;

                                this.checkedItems = [];

                                this.userIsSaving = false;

                                this.tempPreviewUser = _.cloneDeep(user);

                            }

                        }
                    
                    }
                
                }

            }

        },
        previewSearchedUser(listedUser,i){

            let index = this.usersList.findIndex((user) => user.id == -1);

            if(index != -1){

                this.usersList.splice(index,1); 

            }

            if(!this.userSaved){

                let tempIndex = this.usersList.findIndex(x=>x.id == this.tempPreviewUser.id);

                if(tempIndex != -1){

                    this.usersList[tempIndex] = this.tempPreviewUser;

                }

            }

            this.resetImageValidations();

            if(this.$refs.previewFileInput){

                this.$refs.previewFileInput.value = ''

            }

            let user = listedUser;

            this.previewForm = user;
            this.previewForm.index = i;

            this.previewForm.image_src = null;
            this.previewForm.image = user.image;

            let tempUserRoles = [];

            user.roles.forEach(role=>{

                let obj = {
                    name : role.name
                }

                tempUserRoles.push(obj);

            });

            this.previewForm.roles = tempUserRoles;

            this.checkedItems = [];

            this.userIsSaving = false;

            this.tempPreviewUser = _.cloneDeep(user);

        },
        closePreview(){

            if(this.previewForm.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async () => {

                    let index = this.usersList.findIndex((user) => user.id == -1);
                                
                    if(index != -1){
            
                        this.usersList.splice(index, 1);
            
                    }
            
                    if(!this.userSaved){
            
                        let tempIndex = this.usersList.findIndex(x=>x.id == this.tempPreviewUser.id);
                        
                        if(tempIndex != -1){

                            this.usersList[tempIndex] = this.tempPreviewUser;

                        }
            
                    }
                    
                    this.previewForm.id = '';

                    // this.previewForm = _.cloneDeep(this.defaultPreviewForm);
    
                    this.tempUser = _.cloneDeep(this.defaultPreviewForm);
    
                    this.tempPreviewUser = _.cloneDeep(this.defaultPreviewForm);
    
                    this.userSaved = false;
    
                    this.contactFieldTouched = false;
    
                    this.fieldTypePassword = true;
    
                    this.$refs.userFormObserver.reset();

                }).catch(() => {})

            }else{

                let index = this.usersList.findIndex((user) => user.id == -1);
                                
                if(index != -1){
        
                    this.usersList.splice(index, 1);
        
                }
        
                if(!this.userSaved){
        
                    let tempIndex = this.usersList.findIndex(x=>x.id == this.tempPreviewUser.id);
                    
                    if(tempIndex != -1){

                        this.usersList[tempIndex] = this.tempPreviewUser;

                    }
        
                }

                this.previewForm.id = '';

                // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                this.tempUser = _.cloneDeep(this.defaultPreviewForm);

                this.tempPreviewUser = _.cloneDeep(this.defaultPreviewForm);

                this.userSaved = false;

                this.contactFieldTouched = false;

                this.fieldTypePassword = true;

                this.$refs.userFormObserver.reset();

            }

        },
        resetPreview(){

            let index = this.usersList.findIndex((user) => user.id == -1);

            if(index != -1){

                this.usersList.splice(index,1); 

            }

            this.previewForm.id = '';

            // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.tempUser = _.cloneDeep(this.defaultPreviewForm);

            this.tempPreviewUser = _.cloneDeep(this.defaultPreviewForm);

            this.userSaved = false;

            this.contactFieldTouched = false;

            this.fieldTypePassword = true;

            this.$refs.userFormObserver.reset();

        },
        createUser(){

            if(this.usersLoading){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "Users are being retrieved.",
                });

                return

            }

            if(this.userIsSaving){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "A user is currently being processed.",
                });

                return

            }

            let index = this.usersList.findIndex(x=>x.id == -1);

            if(index != -1){

                // this.$notify({
                //   type: "error",
                //   title: "Can't Add New User",
                //   message: "you have an unsaved user in the list",
                // });

                return
            }

            this.closePreview();

            this.resetImageValidations();

            this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.previewForm.id = -1;

            let tempUser = this.previewForm;

            this.usersList.unshift(tempUser);

            this.checkedItems = [];

            this.$refs.userFormObserver.reset();

        },
        //preview form open/close & reset end

        //add/update & delete user start
        validate(form){

            if(form == 'preview'){
                
                this.$refs.userFormObserver.validate().then(success => {

                    if(!success){

                        const errors = Object.entries(this.$refs.userFormObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                        this.$refs.userFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                        if(typeof this.selectedContactObj.valid == 'undefined' || !this.selectedContactObj.valid){

                            this.contactIsValid = false;
                            this.contactFieldTouched = true;

                            document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }

                        if(errors.length > 0){

                            if(this.previewForm.id != -1 && !this.previewForm.image){

                                this.imageValidations.imgIsRequired = true;

                            }else if(this.previewForm.id == -1 && !this.previewForm.addImage){

                                this.imageValidations.imgIsRequired = true;

                            }

                        }else{

                            if(!this.previewForm.image){

                                this.imageValidations.imgIsRequired = true;

                                document.querySelector("#user-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                            }

                        }

                    }else{

                        if(typeof this.selectedContactObj.valid == 'undefined' || !this.selectedContactObj.valid){

                            this.contactIsValid = false;
                            this.contactFieldTouched = true;

                        }

                        if(this.contactIsValid && this.countryIsSelected){

                            if(this.previewForm.id != -1 && !this.previewForm.image){

                                this.imageValidations.imgIsRequired = true;

                                document.querySelector("#user-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                            }else if(this.previewForm.id == -1 && !this.previewForm.addImage){

                                this.imageValidations.imgIsRequired = true;

                                document.querySelector("#user-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                            }else{

                                if(this.previewForm.id == -1){

                                    this.addUserHandle();

                                }else{

                                    this.updateUserHandle();

                                }

                            }

                        }else{

                            document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }

                    }

                });

            }else if(form == 'password'){

                this.$refs.userPasswordObserver.validate().then(success => {

                    if(!success){

                        const errors = Object.entries(this.$refs.userPasswordObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                        this.$refs.userPasswordObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });
                    
                    }else{

                        this.passwordUpdateHandle();

                    }

                });

            }

        },
        async addUserHandle(){
          
            let formData = new FormData();

            formData.append('id',this.previewForm.id);
            formData.append('first_name',this.previewForm.first_name);
            formData.append('last_name',this.previewForm.last_name);
            formData.append('email',this.previewForm.email);
            formData.append('city',this.previewForm.city);
            formData.append('mobile_no',this.previewForm.mobile_no.replace(/\s/g,''));
            formData.append('password',this.previewForm.password);
            formData.append('address_line_1',this.previewForm.address_line_1);
            
            if(this.previewForm.address_line_2){

                formData.append('address_line_2',this.previewForm.address_line_2);

            }

            formData.append('isActive',this.previewForm.isActive == 1 ? 1 : 0);

            formData.append('roles',JSON.stringify(this.previewForm.roles));

            if(this.previewForm.addImage_src){

                formData.append('image',this.previewForm.addImage);

            }

            this.userIsSaving = true;
            try{
                let res = await this.$axios.post('/admin/add',formData);
                if(res.data.status_code == "1001"){
                    
                    this.$notify({
                        title: 'Success',
                        type: 'success',
                        message: res.data.message,
                    });

                    let user = res.data.user;
                    user.isActive = user.isActive ? true : false;

                    this.$store.commit('admin_users_module/add_admin_users_list',user);

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);
                    
                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.userSaved = true;

                    this.resetPreview();

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes('storage limit has been reached') ||
                    error.response.data.error.includes('existing account')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.userIsSaving = false;

            }

        },
        async updateUserHandle(){

            let formData = new FormData();

            formData.append('id',this.previewForm.id);
            formData.append('first_name',this.previewForm.first_name);
            formData.append('last_name',this.previewForm.last_name);
            formData.append('email',this.previewForm.email);
            formData.append('city',this.previewForm.city);
            formData.append('mobile_no',this.previewForm.mobile_no.replace(/\s/g,''));
            formData.append('address_line_1',this.previewForm.address_line_1);

            if(this.previewForm.address_line_2){

                formData.append('address_line_2',this.previewForm.address_line_2);

            }

            formData.append('isActive',this.previewForm.isActive == 1 ? 1 : 0);

            formData.append('roles',JSON.stringify(this.previewForm.roles));

            if(this.previewForm.image_src){

                formData.append('image',this.previewForm.image);

            }

            this.userIsSaving = true;
            try{
                let res = await this.$axios.post('/admin/update',formData);
                if(res.data.status_code == "1190"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: "User has been successfully updated.",
                    });

                    let admin = res.data.admin
                    admin.isActive = admin.isActive ? true : false;

                    let adminIndex = this.usersList.findIndex((user) => user.id == admin.id);

                    let payload = {
                        index: adminIndex,
                        updatedUser: admin
                    }

                    this.usersList[adminIndex] = admin;

                    this.$store.commit('admin_users_module/update_admin_users_list',payload);

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    if(this.previewForm.id == admin.id){

                        this.userSaved = true;

                        this.resetPreview();

                    }

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.id[0],
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached') || 
                    error.response.data.error.includes('existing account')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.userIsSaving = false;

            }

        },
        async updateUserStatusHandle(listedUser,i){

            if(!this.tourIsActive){
            
                if(listedUser.role == "super-admin"){

                    this.$notify({
                        title: 'Alert',
                        type: 'warning',
                        message: "Super admin cannot be changed.",
                    });

                    return 

                }

                if(listedUser.id == this.$store.state.user.id){

                    this.$notify({
                        title: 'Alert',
                        type: 'warning',
                        message: "You cannot perform status changes on yourself.",
                    });

                    return 

                }

                let formData = new FormData()

                formData.append('id', listedUser.id);
                formData.append('status', listedUser.isActive == 1 ? 0 : 1);

                listedUser.isActive = !listedUser.isActive

                let loader = Loading.service({
                    text: "The user is undergoing status update. Please wait!",
                    fullscreen: true,
                });

                try{
                    let res = await this.$axios.post('/admin/update-status',formData);
                    if(res.data.status_code == "3100"){
                        
                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        let payload = {
                            index: i,
                            updatedUser: res.data.user,
                        }

                        this.$store.commit('admin_users_module/update_admin_user_status',payload);

                    }
                }catch(error){

                    // listedUser.isActive = !listedUser.isActive

                    if(error.response){

                        if(error.response.data.error.id){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.id[0],
                            });

                        }else if(error.response.data.error.includes("cannot perform")){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            }); 

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                }

            }

        },
        //add/update & delete user start

        //password form methods start
        passwordPopupHandle(action){

            if(action == 'open'){

                this.passwordForm = {
                    password: '',
                    confirm_password: '',
                    fieldTypePassword:true,
                    confirmFieldTypePassword:true,
                    id: '',
                    isOpen: true,
                    isSaving: false
                }

                this.$refs.userPasswordObserver.reset();

            }else if(action == 'close'){

                this.passwordForm.isOpen = false;

            }
            
        },
        async passwordUpdateHandle(){

            let formData = new FormData();

            formData.append('id',this.previewForm.id);
            formData.append('password',this.passwordForm.password);
            formData.append('password_confirmation',this.passwordForm.confirm_password);

            this.passwordForm.isSaving = true;
            try{
                let res = await this.$axios.post('/admin/update-password',formData);
                if(res.data.status_code == '1191'){

                    this.$notify({
                        title: 'Success',
                        type: 'success',
                        message: res.data.message,
                    });

                    this.previewForm.password = this.passwordForm.password;

                    this.passwordPopupHandle('close');

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes('cannot be the old')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.passwordForm.isSaving = false;

            }

        },
        //password form methods end

        //bulk action start
        userBulkActionHandle(action){

            this.resetPreview();

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then( async () => {

                let formData = new FormData();

                formData.append('resource_type', 'admin_user');
                formData.append("resource_ids", JSON.stringify(this.checkedItems));
                formData.append('action',action);

                let loader = Loading.service({
                    text: "The selected users are undergoing status update. Please wait!",
                    fullscreen: true,
                });

                try{
                    let res = await this.$axios.post('/bulk-action',formData);
                    if(res.data.status_code == "2031"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: "Admins status has been successfully changed.",
                        });

                        let payload = {
                            action: 'statusChange',
                            status: action == "active" ? 1 : 0,
                            listIds: this.checkedItems
                        }

                        this.$store.commit('admin_users_module/admin_users_bulk_action',payload);

                        this.checkedItems = [];

                    }
                }catch(error){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    }); 

                }finally{

                    loader.close();

                }

            }).catch(() => {})

        },
        //bulk action end

        //search vendor start
        searchUser(keyword){

            this.$refs.module_search.searchedKeyword = keyword
            
            this.$refs.module_search.search();

        },
        //search vendor end

        //mobile no. validation methods start
        setMobileNoFieldValidations(validations){

            this.contactIsValid = validations.isContactValid
            this.countryIsSelected = validations.isCountrySelected
            this.contactFieldTouched = validations.isFieldTouched
            this.selectedContactObj = validations.contactObj

            this.previewForm.mobile_no = validations.addContactNo

        },
        //mobile no. validation methods end

        //other methods start
        async changeAddImage(e){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    this.imageValidations.imgIsRequired = false

                    if(this.$refs.addPreviewFileInput){

                        this.$refs.addPreviewFileInput.value = ''

                    }

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                    
                    this.previewForm.addImage = file;
                    this.previewForm.addImage_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false

                    if(this.$refs.addPreviewFileInput){

                        this.$refs.addPreviewFileInput.value = ''

                    }

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6)
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(!this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                    }
                    
                    // this.imageValidations.imgInvalidFormat = true

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    if(!this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                    }

                    // this.imageValidations.isNotImg = true

                }

            }

        },
        async handleChangeEditImage(e){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 
                    
                    if(this.$refs.previewFileInput){

                        this.$refs.previewFileInput.value = ''

                    }

                    this.imageValidations.imgIsRequired = false

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                
                    this.previewForm.image = file;
                    this.previewForm.image_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6)
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(!this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                    }
                    
                    // this.imageValidations.imgInvalidFormat = true

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    if(!this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                    }

                    // this.imageValidations.isNotImg = true

                }

            }

        },
        resetImageValidations(){
            
            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

        },
        generatePassword(form,length){

            let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+|}{?><:~``}"
            let retVal = "";

            for(var i = 0, n = charset.length; i < length; ++i){

                retVal += charset.charAt(Math.floor(Math.random() * n));

            }

            if(form == 'preview'){

                if(!this.tourIsActive){

                    this.$refs.passwordInput.value = retVal;
                    this.previewForm.password = retVal;

                    this.$refs.passwordInput.focus();

                }

            }else if(form == "reset"){

                this.passwordForm.password = retVal;

            }

        },
        generateUrl(listedUser){
            
            if(listedUser.image){

                if(typeof(listedUser.image) == "string"){

                    return this.base_image_url + this.settings.store_id + '/user/' + listedUser.image

                }else{

                    return listedUser.image_src

                }

            }
            
        },
        checkedItemHandle(userId){

            if(!this.tourIsActive && userId != -1 && !this.previewForm.id){

                let index = this.checkedItems.findIndex(item => item == userId);

                if(index != -1){

                    this.checkedItems.splice(index,1)

                }else{

                    this.checkedItems.push(userId)

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive && !this.previewForm.id){

                if((this.checkedItems.length == this.usersList.length) ||
                (this.checkedItems.length == this.usersList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.usersList.length){

                    this.checkedItems = [];
                    
                    this.usersList.forEach(user => {

                        if(user.id != -1){

                            this.checkedItems.push(user.id);

                        }

                    });

                }

            }

        },
        clearSelection(){

            MessageBox.confirm(
                'Do you really want to uncheck the selected records?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async () => {

                this.checkedItems = [];

            }).catch(() => {})

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        userManagementKeyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm && this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm && this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }

            }

        },
        //other methods end

        //tour methods start
        startUserManagementTour(){

            this.$tours.userManagementTour.start();

            this.tourIsActive = true;

            if(this.previewForm.id != ''){

                this.resetPreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                document.querySelector(".tableActionBtns").style.position = "static"

                document.querySelector(".productSlideBox").style.overflowY  = "hidden"

                this.createUser();

            }

            if(currentStep >= 19){

                document.querySelector(".tableActionBtns").style.position = "sticky"

            }

            if(currentStep + 1 == 21){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand()

                }

            }

            if(currentStep + 1 == 22){

                this.expand()

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                this.resetPreview();

                document.querySelector(".tableActionBtns").style.position = "sticky"

                document.querySelector(".productSlideBox").style.overflowY  = "auto"

            }

            if(currentStep <= 20){

                document.querySelector(".tableActionBtns").style.position = "static"

            }

            if(currentStep == 21){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep == 22){

                this.expand();
            
            }

        },
        handleTourStop(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false;

        },
        //tour methods end

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },

    },
    mounted(){

        this.$refs.usersTable.addEventListener('scroll', this.handleScrollEvent);

        window.addEventListener('keydown',this.userManagementKeyEvent);

        window.addEventListener('beforeunload', (event) => {

            let index = null;

            this.usersList.forEach((user) => {

                if(user.id == -1){

                    index = this.usersList.indexOf(user);

                }

            });

            if(index != null){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        //master search
        if(this.$route.query.search){
      
            this.$refs.module_search.searchedKeyword = this.$route.query.search

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true

            }

            this.$router.replace({'query': null});

            this.$refs.module_search.search();

        }

    },
    beforeMount(){

        this.usersLoading = true;
        try{
            if((!this.storedAdmins || this.storedAdmins.length == 0) && this.adminSearchKeyword.length == 0){

                this.$store.dispatch('admin_users_module/fetchStoreAdminUsersRequest',{size: 10, page: 1, loadData: 'new'})

            }else{

                this.usersList = _.cloneDeep(this.storedAdmins);
                this.usersLoading = false;

            }

            if(!this.storeAdminRoles || this.storeAdminRoles.length == 0){

                this.$store.dispatch('admin_users_module/fetchAdminRolesRequest');

            }else{

                this.rolesList = this.storeAdminRoles

            }

        }catch(error){

            this.usersLoading = false;

            this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener('keydown', this.userManagementKeyEvent);

    },
    beforeRouteLeave(to, from, next){

        let index = null;

        this.usersList.forEach((user) => {

            if(user.id == -1){

                index = this.usersList.indexOf(user);

            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                this.resetPreview();

                next();

            }).catch(()=>{})

        }else{

            next();

        }

    },
}
window.myFunction = function (event) {
    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {
      item.classList.remove("active");
    });
    // mark as active selected menu item
    event.target.classList.add("active");
};
</script>

<style>
    .active-filter{
        border: 1px solid #1fc2c2;
        border-radius: 50px;
        padding: 0px 10px;
        text-align: center;
        color: #1fc2c2;
    }
    .formContentBox .passwordEyeBtn {
        background-color: transparent;
        padding: 0px;
        margin: 0px;
        border: none;
        width: 17px;
        height: 17px;
        position: absolute;
        top: 12px;
        right: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .formContentBox .changePasswordEyeBtn{
        background-color: transparent;
        padding: 0px;
        margin: 0px;
        border: none;
        width: 17px;
        height: 17px;
        position: absolute;
        top: 44px;
        right: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .tableBodyCtmTxt.admins svg{
        width: 25px;
    }
</style>